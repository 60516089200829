body {
   
font-family: 'Open Sans', sans-serif;
letter-spacing: 0.05rem;

}
h1 {
    font-family: 'Dancing Script', cursive;
    line-height: 1,5em;
   text-shadow:2px 2px 4px rgb(7, 7, 7);
   color: #916C2E;
}


h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
  color: #916C2E;
   
}

p {
  font-family: 'Open Sans', sans-serif;
    font-style: italic;
}
 .btn {
   background-color: #78B497;
 }
 .btn:hover {
  background-color: green;
 }

 #forms {
   padding-top: 10px;
   padding-bottom: 40px;
   
  background-image: linear-gradient(90deg, #eef2f5 , #78B497 80%);
 }

 #logo {
   width: 40px;
 }


  /* css blog*/

.main-row {
 
    margin-bottom: 8%;
    background-color:rgba(255, 255, 255, 0.705);
    box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
    border-radius: 0.5rem;
    
  }
  
  .blog-img > img {
   width: 250px;
   transform: translateY(-30px);
   border-radius: 50%;
   box-shadow: 0 0 8px 3px rgba(0,0,0,.3);
  }
  .blog-title > h3 {
    line-height: 2rem;
  }
  @media (min-width: 500px) {
  .blog-title > h3 {
    line-height: 3rem;
  }
  }
  @media (min-width: 1100px) {
    .main-row {
      max-width: 800px;
      position: relative;
      left: 15%;
    }
    }

  
    